import * as serviceWorkerRegistration from './serviceWorkerRegistration';

require.context('../images', true);

require('jquery');
require('jquery-ui');
require('jquery-ujs');

import 'bootstrap';
import '../javascripts/common/cookiechoices';

$(function () {
  $('.main-nav a:not(.dropdown-toggle)').bind('click', function (event) {
    var $anchor = $(this);

    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $($anchor.attr('href')).offset().top,
        },
        1500,
        'easeInOutExpo',
      );

    event.preventDefault();
  });
});

serviceWorkerRegistration.register();
